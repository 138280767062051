var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Condicionada")])
          ])
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "v-row",
            { staticClass: "form-row-result", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: [..._vm.style.colLeftClasses, "form-result-label"],
                  attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                },
                [_vm._v("\n        Código Proposta\n      ")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.proposta.codigoProposta) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "form-row-result", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: [..._vm.style.colLeftClasses, "form-result-label"],
                  attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                },
                [_vm._v("\n        Código Avaliação\n      ")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.proposta.codigoAvalicao) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "form-row-result", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: [..._vm.style.colLeftClasses, "form-result-label"],
                  attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                },
                [_vm._v("\n        Código do Correspondente\n      ")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.proposta.codigoCorrespondente) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "form-row-result", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: [..._vm.style.colLeftClasses, "form-result-label"],
                  attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                },
                [_vm._v("\n        CPF\n      ")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                },
                [_vm._v("\n        " + _vm._s(_vm.proposta.cpf) + "\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "form-row-result", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: [..._vm.style.colLeftClasses, "form-result-label"],
                  attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                },
                [_vm._v("\n        Condição\n      ")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.proposta.condicao) + "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "form-row-result", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: [..._vm.style.colLeftClasses, "form-result-label"],
                  attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                },
                [_vm._v("\n        Valor\n      ")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                },
                [_vm._v("\n        " + _vm._s(_vm.proposta.valor) + "\n      ")]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "form-row-result", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: [..._vm.style.colLeftClasses, "form-result-label"],
                  attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                },
                [_vm._v("\n        Resposta SIRIC\n      ")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.proposta.resposta) + "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }