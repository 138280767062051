import { render, staticRenderFns } from "./CaixaquiCadastroClienteFormulariosEnviadosReprovados.vue?vue&type=template&id=5f5de2ad&scoped=true&"
import script from "./CaixaquiCadastroClienteFormulariosEnviadosReprovados.vue?vue&type=script&lang=js&"
export * from "./CaixaquiCadastroClienteFormulariosEnviadosReprovados.vue?vue&type=script&lang=js&"
import style0 from "../../../components/caixaqui/caixaqui-style.css?vue&type=style&index=0&id=5f5de2ad&scoped=true&lang=css&"
import style1 from "./CaixaquiCadastroClienteFormulariosEnviadosReprovados.vue?vue&type=style&index=1&id=5f5de2ad&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f5de2ad",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f5de2ad')) {
      api.createRecord('5f5de2ad', component.options)
    } else {
      api.reload('5f5de2ad', component.options)
    }
    module.hot.accept("./CaixaquiCadastroClienteFormulariosEnviadosReprovados.vue?vue&type=template&id=5f5de2ad&scoped=true&", function () {
      api.rerender('5f5de2ad', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/caixaqui/cadastro-clientes/CaixaquiCadastroClienteFormulariosEnviadosReprovados.vue"
export default component.exports