<!--suppress ALL -->
<template>
  <v-container>
    <v-row no-gutters>
      <v-col class="form-header text-center">
        <div>Condicionada</div>
      </v-col>
    </v-row>

    <div>
      <v-row class="form-row-result" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Código Proposta
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ proposta.codigoProposta }}
        </v-col>
      </v-row>

      <v-row class="form-row-result" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Código Avaliação
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ proposta.codigoAvalicao }}
        </v-col>
      </v-row>

      <v-row class="form-row-result" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Código do Correspondente
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ proposta.codigoCorrespondente }}
        </v-col>
      </v-row>

      <v-row class="form-row-result" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          CPF
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ proposta.cpf }}
        </v-col>
      </v-row>

      <v-row class="form-row-result" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Condição
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ proposta.condicao }}
        </v-col>
      </v-row>

      <v-row class="form-row-result" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Valor
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ proposta.valor }}
        </v-col>
      </v-row>

      <v-row class="form-row-result" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Resposta SIRIC
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ proposta.resposta }}
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
// noinspection ES6CheckImport
import { OPTIONS } from "../../../config";
import { mapActions } from "vuex";

export default {
  name: "CaixaquiCadastroClienteFormulariosEnviadosCondicionados",

  data: () => ({
    masks: OPTIONS.masks,
    formularios: [],
    loading: false,
    proposta: {},
    cpf: "",
    search: "",
    style: {
      colLeft: 4,
      colRight: 8,
      alignSelf: "stretch",
      colLeftClasses: ["form-col-left", "d-flex", "flex-row", "align-center"],
      colRightClasses: ["form-col-right", "d-flex", "flex-row", "align-center"]
    },
    footer: {
      itemsPerPageOptions: [10, 25, 50],
      showCurrentPage: true,
      showFirstLastPage: true
    }
  }),
  created() {
    this.proposta = {};
    this.loadPropostaAprovada();
  },
  methods: {
    ...mapActions("formulariosEnviados", ["loadFormularios"]),
    searchFormularios() {
      this.formularios = [];
      this.loading = true;
      this.loadFormularios(this.cpf)
        .then(value => {
          console.log("forms: " + value);
          this.formularios = value;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleForwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE AVANCAR PARA A PROXIMA PAGINA
      if (to) {
        this.$router.push(to);
      }
    },
    handleBackwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE VOLTAR PARA A PAGINA
      if (to) {
        this.$router.push(to);
      }
    },
    loadPropostaAprovada() {
      this.$log.debug("this.$route.params.proposta", this.$route.params.proposta);
      if (this.$route.params.proposta) {
        this.proposta = this.$route.params.proposta;
      } else {
        this.proposta = {
          codigoProposta: ""
        };
      }
    }
  }
};
</script>

<style scoped src="../../../components/caixaqui/caixaqui-style.css"></style>
<style scoped>
.datalink {
  color: black !important;
  font-weight: bold;
}
</style>
