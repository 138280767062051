<!--suppress ALL -->
<template>
  <v-container>
    <v-row no-gutters>
      <v-col class="form-header text-center color-text">
        <div>Negadas</div>
      </v-col>
    </v-row>

    <div>
      <v-row class="form-row-result" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Código Proposta
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ proposta.codigoProposta }}
        </v-col>
      </v-row>

      <v-row class="form-row-result" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Código Avaliação
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ proposta.codigoAvalicao }}
        </v-col>
      </v-row>

      <v-row class="form-row-result" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Código do Correspondente
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ proposta.codigoCorrespondente }}
        </v-col>
      </v-row>

      <v-row class="form-row-result" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          CPF
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ proposta.cpf }}
        </v-col>
      </v-row>

      <v-row class="form-row-result" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Nome do Cliente
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ proposta.nomeCliente }}
        </v-col>
      </v-row>

      <v-row class="form-row-result" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Motivo
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ proposta.motivo }}
        </v-col>
      </v-row>

      <v-row class="form-row-result" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Resposta SIRIC
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ proposta.resposta }}
        </v-col>
      </v-row>

      <v-row class="form-row-result" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Identificação do Operador Solicitante
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ proposta.operador }}
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
// noinspection ES6CheckImport
import { mapAvaliacaoRiscoFields } from "../../../store/modules/caixaqui-avaliacao-risco";
import { OPTIONS } from "../../../config";
import { mapActions } from "vuex";

export default {
  name: "CaixaquiCadastroClienteFormulariosEnviadosReprovados",

  data: () => ({
    masks: OPTIONS.masks,
    formularios: [],
    loading: false,
    proposta: {},
    cpf: "",
    search: "",
    style: {
      colLeft: 4,
      colRight: 8,
      alignSelf: "stretch",
      colLeftClasses: ["form-col-left", "d-flex", "flex-row", "align-center"],
      colRightClasses: ["form-col-right", "d-flex", "flex-row", "align-center"]
    },
    footer: {
      itemsPerPageOptions: [10, 25, 50],
      showCurrentPage: true,
      showFirstLastPage: true
    },
    headers: [
      { text: "No Prop.", align: "left", sortable: true, value: "numero" },
      { text: "Data Remessa", align: "left", sortable: true, value: "dataRemessa" },
      { text: "CPF Cliente", align: "left", sortable: true, value: "cpf", width: "140px" },
      { text: "Resultado da Análise de Conformidade", align: "left", sortable: true, value: "resultadoConformidade" },
      { text: "Produto", align: "left", sortable: true, value: "produto" },
      { text: "Resultado da Avaliação de Risco", align: "left", sortable: true, value: "action", width: "80px" },
      { text: "Validade da Avaliação de Risco", align: "left", sortable: true, value: "validade" },
      { text: "Tipo de Pedido", align: "left", sortable: true, value: "tipoPedido" }
    ]
  }),
  computed: {
    ...mapAvaliacaoRiscoFields({
      cnpjConstrutora: "autoFinanciamento.cnpjConstrutora",
      prestacoes: "autoFinanciamento.prestacoes"
    })
  },
  created() {
    this.proposta = {};
    this.loadPropostaAprovada();
  },
  methods: {
    ...mapActions("formulariosEnviados", ["loadFormularios"]),
    searchFormularios() {
      this.formularios = [];
      this.loading = true;
      this.loadFormularios(this.cpf)
        .then(value => {
          console.log("forms: " + value);
          this.formularios = value;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadPropostaAprovada() {
      this.$log.debug("this.$route.params.proposta", this.$route.params.proposta);
      this.proposta = this.$route.params.proposta;
    },
    handleForwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE AVANCAR PARA A PROXIMA PAGINA
      if (to) {
        this.$router.push(to);
      }
    },
    handleBackwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE VOLTAR PARA A PAGINA
      if (to) {
        this.$router.push(to);
      }
    },
    visualizar(proposta) {
      console.log("Proposta: " + proposta);
    }
  }
};
</script>

<style scoped src="../../../components/caixaqui/caixaqui-style.css"></style>
<style scoped>
.datalink {
  color: black !important;
  font-weight: bold;
}
.color-text {
  background-color: red;
}
</style>
