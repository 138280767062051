var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("CPF do Cliente")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "form-row form-row-one-col text-center" },
            [
              _c("div", { staticStyle: { "margin-bottom": "3px" } }, [
                _c("input", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: _vm.masks.cpf,
                      expression: "masks.cpf"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.cpf,
                      expression: "cpf"
                    }
                  ],
                  attrs: {
                    id: "cpf",
                    maxlength: "14",
                    name: "cpf",
                    size: "23",
                    type: "text"
                  },
                  domProps: { value: _vm.cpf },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.cpf = $event.target.value
                    }
                  }
                }),
                _c(
                  "a",
                  {
                    staticClass: "btn-azul btn-padrao",
                    attrs: { id: "btn-consultar-form" },
                    on: { click: _vm.searchFormularios }
                  },
                  [_vm._v("Consultar")]
                )
              ])
            ]
          )
        ],
        1
      ),
      _c("v-row", { attrs: { "no-gutters": "" } }, [
        _c(
          "div",
          { staticClass: "d-flex justify-center mt-12" },
          [
            _c(
              "v-card",
              { staticClass: "pa-1", attrs: { outlined: "", tile: "" } },
              [
                _vm.exibe
                  ? _c("v-data-table", {
                      staticClass: "elevation-0",
                      attrs: {
                        "footer-props": _vm.footer,
                        headers: _vm.headers,
                        "hide-default-footer": true,
                        items: _vm.formularios,
                        loading: _vm.loading,
                        search: _vm.search,
                        "item-key": "numero"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "item.action",
                            fn: function({ item }) {
                              return [
                                _c(
                                  "v-input",
                                  {
                                    attrs: { text: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.visualizar(item)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "datalink",
                                        attrs: { href: "#" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.resultadoAvaliacaoRisco
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2676134000
                      )
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }