<!--suppress ALL -->
<template>
  <v-container>
    <v-row no-gutters>
      <v-col class="form-header text-center">
        <div>CPF do Cliente</div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="form-row form-row-one-col text-center">
        <div style="margin-bottom: 3px">
          <input id="cpf" maxlength="14" name="cpf" size="23" type="text" v-mask="masks.cpf" v-model="cpf" />
          <a id="btn-consultar-form" @click="searchFormularios" class="btn-azul btn-padrao">Consultar</a>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <div class="d-flex justify-center mt-12">
        <v-card class="pa-1" outlined tile>
          <v-data-table
            v-if="exibe"
            :footer-props="footer"
            :headers="headers"
            :hide-default-footer="true"
            :items="formularios"
            :loading="loading"
            :search="search"
            class="elevation-0"
            item-key="numero"
          >
            <template v-slot:item.action="{ item }">
              <v-input text @click="visualizar(item)">
                <a href="#" class="datalink"> {{ item.resultadoAvaliacaoRisco }} </a>
              </v-input>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-row>
  </v-container>
</template>

<script>
// noinspection ES6CheckImport
import { OPTIONS } from "../../../config";
import ROUTERS from "../../../routes/router-constants";
import { mapActions } from "vuex";

export default {
  name: "CaixaquiCadastroClienteFormulariosEnviados",

  data: () => ({
    masks: OPTIONS.masks,
    formularios: [],
    loading: false,
    exibe: false,
    cpf: "",
    search: "",
    style: {
      colLeft: 4,
      colRight: 8,
      alignSelf: "stretch",
      colLeftClasses: ["form-col-left", "d-flex", "flex-row", "align-center"],
      colRightClasses: ["form-col-right", "d-flex", "flex-row", "align-center"]
    },
    footer: {
      itemsPerPageOptions: [10, 25, 50],
      showCurrentPage: true,
      showFirstLastPage: true
    },
    headers: [
      { text: "No Prop.", align: "left", sortable: true, value: "numero" },
      { text: "Data Remessa", align: "left", sortable: true, value: "dataRemessa" },
      { text: "CPF Cliente", align: "left", sortable: true, value: "cpf", width: "140px" },
      { text: "Resultado da Análise de Conformidade", align: "left", sortable: true, value: "resultadoConformidade" },
      { text: "Produto", align: "left", sortable: true, value: "produto" },
      { text: "Resultado da Avaliação de Risco", align: "left", sortable: true, value: "action", width: "80px" },
      { text: "Validade da Avaliação de Risco", align: "left", sortable: true, value: "validade" },
      { text: "Tipo de Pedido", align: "left", sortable: true, value: "tipoPedido" }
    ]
  }),
  methods: {
    ...mapActions("formulariosEnviados", ["loadFormularios"]),
    searchFormularios() {
      this.formularios = [];
      this.loading = true;
      this.loadFormularios(this.cpf)
        .then(value => {
          console.log("forms: " + value);
          this.formularios = value;
          this.exibe = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    visualizar(proposta) {
      console.log("Proposta: " + proposta);
      if (proposta.tipoPropostaEnum == "APROVADA") {
        let query = proposta.propostaAprovada;
        this.$router.push({ name: ROUTERS.caixaqui.formulariosEnviadosAprovados.name, params: { proposta: query } });
      } else if (proposta.tipoPropostaEnum == "REPROVADA") {
        let query = proposta.propostaNegada;
        this.$router.push({ name: ROUTERS.caixaqui.formulariosEnviadosReprovados.name, params: { proposta: query } });
      } else if (proposta.tipoPropostaEnum == "CONDICIONADA") {
        let prop = proposta.propostaCondicionada;
        this.$router.push({ name: ROUTERS.caixaqui.formulariosEnviadosCondicionados.name, params: { proposta: prop } });
      }
    }
  }
};
</script>

<style scoped src="../../../components/caixaqui/caixaqui-style.css"></style>
<style scoped>
.datalink {
  color: black !important;
  font-weight: bold;
}
</style>
